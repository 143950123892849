body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-height: -webkit-fill-available;
}


html {
  height: -webkit-fill-available;
}

#root, .ion-page {
  height: 100%;
}


.app-container-grid {
  --side-max-width: 100%;
  height: 100%;
  padding: 0;

  .content-col {
    padding: 0;
    height: 97%;
  }

  .footer-col {
    height: 3%;
    //position: relative;
    padding: 0;
  }

}

ion-card {
  margin-inline: 0;
  background: #E9F7FC;
}

ion-card-content {
  h1 {
    margin-bottom: 20px!important;
  }

  p {
    margin-bottom: 10px!important;
  }

  .helpText {
    color: #000000 !important
  }
}

i {
  margin-left: 10px;
  border: solid #3f90b0;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.Collapsible {

  .strong-header {
    font-weight: 600;
  }

  .strong-underlined-text {
    font-weight: 900;
    text-decoration: underline;
  }

  a, .fake-href {
    text-decoration: underline;
    color: rgba(209,0,73,1.0)!important;    
    cursor: pointer;
  }

  .Collapsible__trigger h6 {
    display: inline-block;
  }

  .break-line {
    margin-bottom: .5rem;
  }
}

#itemTop, #itemMiddle, #itemBottom, .next-menu-level-expander {
  position: relative;

  .expanded {
    transform: rotate(-135deg);
    transition: .1s linear;
    display: inline-block;
    margin-bottom: -2px;
    margin-right: 2px;
  }

  .collapsed {
    transform: rotate(45deg);
    transition: .1s linear;
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 2px;
  }
}

.translate-text {
  font-size: 10px;
  color: #000000;
}

.strong-text {
  font-weight: 900;
}

.submit-error-title {
  font-weight: 900;
  color: #A00338;
}

.strong-text.green-text {
  color: #117864;
}

.strong-text.red-text {
  color: #D10049;
}

.self-employment-container {
  background: #f5f7fa;

  .income-text {
    color: #117864;
  }

  .expenses-text {
    color: #A800FF;
  }
}


ion-grid {
  --ion-grid-width-md: 540px;
  --ion-grid-width-lg: 540px;
  --ion-grid-width-xl: 540px;

  a {
    text-decoration: underline;
    color: rgba(209,0,73,1.0)!important;
  }

  footer a {
    color: #ffffff!important;
    text-decoration: none;
  }

  footer a:hover {
    color: #ffffff;
  }
}

ion-title {
  color: #000000;
  padding-inline: 0;
  padding-left:0;
}

ion-toolbar {
  --background: #3f90b0;
  color: #ffffff;

  .banner-white {
    height: 20px;
    background: url("../images/untied-white-logo.png") center no-repeat;
    background-size: contain;
  }

  .banner-container {
    width: 177px;
    margin-left: 13px;
    font-size: 10px;
  }
}

ion-menu {
  backdrop-filter: blur(1px);
  border-right: 1px solid silver;

  ion-note {
    width: 100%;
    padding-top: 5px;
  }
}

ion-content ion-toolbar {
  --background: white;
}

ion-icon {
  --ion-color-primary: #3f90b0;
  margin-right: 10px;
  cursor: pointer;
}

ion-item-option {
  --background: #941e3b
}

.my-ion-popover {
  --width: 400px;
  --max-width: 360px;
}

.employment-pages-nav-button {
  --background: #3f90b0;
  color: #ffffff;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 1;
  filter: invert(1);
}

.money-input {
  /* --border-color: transparent!important;
  --highlight-height: 0;
  border: 1px solid #dedede;
  border-width: 0 0 1px 0;
  border-radius: 4px; */
  text-align:right;
  padding:0;

  ion-input {
    --padding-bottom: 0;
    --padding-top: 0;
  }
}

.money-select ion-select {
  --padding-start: 0;
  width:100%;
  max-width:100%
}

.selectable {
  -webkit-user-select: text;
  -khtml-user-select: auto;
  -moz-user-select: all;
  -ms-user-select: auto;
  -o-user-select: auto;
  user-select: auto;
}

.translate-flags {
  width: 48px;
  height: 22px;
  border: .5px solid;
  background: url("../images/flags.png") center no-repeat;
  background-size: contain;
}

.p-60 {
  width: 100%;
  height: 161px;
  background: url("../images/P60.png") center no-repeat;
  background-size: contain;
}

.banner-blue {
  width: 100%;
  height: 51px;
  background: url("../images/untied-logo-text.png") center no-repeat;
  background-size: contain;
}

.translate-container {
  padding-inline-end: 0;

  .sc-ion-buttons-md-s ion-button {
    --padding-end: 0;
  }
}

ion-button {
  --padding-start: 0;
  --padding-end: 0;
}

.login-page-container {
  /*background-color: #3f90b0;*/
  background-color: white;
  width: 100%;
  padding: 0px;
  font-size: 14px;
  color: #000000;    

  >ion-row {
    height: 100%;

    >ion-col {
      height: 100%;
      position: relative;
    }
  }

  .app-name {
    font-size: 18px;
    font-weight: 700;
  }

  .banner-white {
    margin-top: 20px;
    margin-bottom: 20px;
    flex: 0.6 1 0%;
    height: 51px;
    background: url("../images/untied-white-logo.png") center no-repeat;
    background-size: contain;
  }

  .banner-black {
    margin-top: 20px;
    margin-bottom: 5px;
    flex: 0.6 1 0%;
    height:92px;
    background: url("../images/untied-with-text.png") center no-repeat;
    background-size: contain;
  }

  .position-absolute {
    width: 100%;
    left: 0;

    @media screen and (device-aspect-ratio: 40/71) {
      top: 0;
    }
  }

  ion-card-content {
    position: relative;
    height: 100%;
    max-width: 400px;
    margin: auto;
  }

  .translate {
    position: absolute;
    top: 0;
    right: 0;
  }

  footer {
    background: transparent;
  }

  a {
    color: #ffffff;
  }
}

.year-selector {
  width: 100%;

  .MuiSelect-icon {
    color: #3f90b0;
  }
}

.year-selector.MuiInputBase-root.Mui-disabled {
  color: #000000;
}

.year-selector.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}
.year-selector.MuiInput-underline:before,
.year-selector.MuiInput-underline:hover:not(.Mui-disabled):before,
.year-selector.MuiInput-underline:after,
.year-selector.MuiInput-underline:hover:not(.Mui-disabled):after
{
  border-bottom: 1px solid #3f90b0;
}

.MuiSelect-icon.Mui-disabled {
  display: none;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #0C90B2;
  color: #ffffff;
  padding-left: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 0;
  height: 47px;
  justify-content:center;
}

.MuiInput-input::placeholder {
  font-style:italic;
}

.MuiAutocomplete-root {
  width: 100%;
}

.MuiButton-root {
  text-transform:none!important;
}

.selected .MuiInputLabel-shrink {
  margin-left: -30px;
}

.bank-selector .MuiFormControl-root.MuiTextField-root {

  background: transparent;
  border: none;
  margin-bottom: 0;
  padding-left: 0;

  .MuiFormLabel-root {
    color: #000000;
    font-size: .9rem;
  }

  .MuiInputLabel-shrink, .MuiIconButton-label {
    color: #3f90b0;
  }

  .MuiInput-underline:after {
    border: none;
  }

  .MuiInput-underline:before, .MuiInput-underline:hover:before, .MuiInput-underline:active:before {
    border: none;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}



.ion-button-money {
  --background: #3f90b0;
  --color: #ffffff;

  ion-item {
    --background-hover-opacity: .2;
    --color-hover: #ffffff;
    --color-focused: #ffffff;

    :active {
      color: #ffffff;
    }

    ion-label {
      --color-focused: #ffffff;
      --font-size: 14px;
      line-height: 20px;
    }
  }
}

.btn-translate {
  border:none!important;
  background-color:white!important;
  color:#3f90b0!important;
  text-transform:none!important;
  text-decoration-line:underline;
}

.input-container {
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: 14px;
  --background-hover: transparent;
  --highlight-color-focused: #3f90b0;
  --highlight-height: 0;
  --ion-color-primary: #3f90b0;

  ion-label {
    white-space: normal !important;
    padding-right: 30px;

    ion-icon, a ion-icon {
      position: absolute;
      bottom: 3px;
    }

    a ion-icon {
      bottom: 14px;
      margin-left: 10px;
    }
  }

  ion-select, .native-input {
    --padding-bottom: 0;
  }

  .in-item {
    margin: 0;
  }

  ion-checkbox {
    --border-width: 2px;
    --border-color: #3f90b0;
    --background-checked: #3f90b0;
    --border-color-checked: #3f90b0;
  }

  ion-select {
    --padding-start: 0;
    max-width: 100%;
    width: 100%;
  }
}

.subscription-button{
  display: block;
  max-width: 200px;
  margin: auto;
  height: 80px;
  --background: #3f90b0;
  color: #ffffff;
  margin-bottom: 10px;

  h6::first-letter {
    text-transform: capitalize;
  }
}

.submit-error-image-container {
  height: 150px;
  background: url("../images/nay-taxberry-centred.png") center no-repeat;
  background-size: contain;
}

.submit-success-image-container {
  height: 150px;
  background: url("../images/yay-taxberry-centred.png") center no-repeat;
  background-size: contain;
}

.submit-congrats-image-container {
  height: 250px;
  background: url("../images/yay-man.png") center no-repeat;
  background-size: contain;
}

.submit-error-image-container.small, .submit-success-image-container.small {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.alert-button-group {
  .alert-button {
    // border: 1px solid #3f90b0;
    // border-radius: 4px;
    // color: #3f90b0;
    background: #3f90b0;  
    border-radius: 4px; 
    color:white;
    margin-bottom:10px;

    margin-right: 0;
    display: inline;
    font-size: .7rem;

    .alert-button-inner.sc-ion-alert-md {
      justify-content: center;
    }
    
    
  }
  .ion-button-money {
    background: #3f90b0;
    color: white;
    box-shadow: none;
  }  

}

.alert-button-group.sc-ion-alert-md {
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  -webkit-padding-start: 24px;
  -webkit-padding-end: 24px;
  display: flex;
  justify-content: space-between;
}

.alert-cancel-button {
  background:white!important;
  border: 1px solid #3f90b0!important;
  color:#3f90b0!important;
}

.alert-title.sc-ion-alert-md {
  color: #0C90B2!important;
  text-align:left;
}



.login-alert .alert-button-group.sc-ion-alert-md {
  justify-content: center;
  margin-bottom:20px;
}

.login-alert .alert-button.sc-ion-alert-md {
  background: #3f90b0;  
  border-radius: 4px; 
  width:100px;
  font-size:16px;
  color:white;
}

.fake-anchor {
  text-decoration: underline;
  color: rgba(209,0,73,1.0)!important;
  cursor: pointer;
}

.utrAlert {
  --max-height:450px;
}

.utrAlert .alert-message {
  max-height:450px;
}

.utrAlert h2 {
  color: #0C90B2!important;
  text-align:left!important;
}

// .utrAlert a {
//   text-decoration: underline;
//   color: rgba(209,0,73,1.0)!important;
// }

.alert-message a {
  text-decoration: underline;
  color: rgba(209,0,73,1.0)!important;
}

.utrAlert .alert-button.sc-ion-alert-md {
  background: #3f90b0;  
  border-radius: 4px; 
  font-size:16px;
  color:white;
  margin-bottom: 10px;
}

// .page-header {
//   height:40px;
// }

.tax-breakdown-alert {
 --width: 400px;
 --max-width:90%;
 font-size:14px;
}

.page-header::after {
  background:none;
}

.page-header-title {
  color:#0C90B2;
}

h5 {
  color:#0C90B2;
  font-weight: 700;
}

.man-red {
  height: 100px;
  background: url("../images/man-red.png") center no-repeat;
  background-size: contain;
}

.hmrc-recognised {
  width:80%;
  height:90px;
  background: url("../images/hmrc-recognised.png") center no-repeat;
  background-size: contain;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #3f90b0;
  color: #ffffff;
  line-height: 2;
  font-size: 10px;

  @media (min-width:540px)  {
    font-size: 16px;
  }


  ion-grid, ion-col {
    padding: 0;
  }
}

.date-selector-label {
  color: #0C90B2;
}

.journey-icon {
  margin-bottom:5px;
  margin-right:10px;
  vertical-align: center;
}
// .wrong-plan{
//   min-width: 400px;
//   width: 600px;
// }

.wrong-plan .popover-wrapper .popover-content{
  min-width: 350px;
  width: fit-content;
  position: initial;
  
}

.employment-popover .popover-content{
  min-width: 400px;
  position: initial;
  
}