ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.cov-checkbox svg { 
            width: 18px;
            font-size: 32px;
            --border-width: 2px; 
            --border-color: #3f90b0; 
            --background-checked: #3f90b0;
            justify-content: flex-end;
            margin-right: 10px;
          }


ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 0px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 0px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-item {
  --border-color: #3f90b0;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  border-radius: 4px;
}

ion-menu ion-item.sub-menu{
  --padding-start: 45px;
}

ion-menu ion-item.sub-sub-menu {
    --padding-start: 90px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.item-lines-none{
    --background-hover: #3f90b0;
    --background-hover-opacity: .1;
}

.ion-button-money {
  --background: #3f90b0;
  --box-shadow: none;
  margin: 10px 10px;
  min-width: 70px;
  width: 150px;
}
.ion-button-money {
  --background: #3f90b0;
  --box-shadow: none;
  margin: 10px 10px;
  min-width: 70px;
  width: 150px;
}
.ion-button-money-inverse {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #3f90b0;
  --color: #3f90b0;
  --background: white;
  --background-hover: grey;
  --box-shadow: none;
  margin: 10px 10px;
  min-width: 70px;
  width: 150px;
}

.ion-button-inverse {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #3f90b0;
  --color: #3f90b0;
  --background: white;
  --background-hover: grey;
  --box-shadow: none;
}
.ion-button-inverse ion-item {
  --color: #3f90b0;
  --background: white;
  --background-hover: grey;

}
.ion-button-inverse ion-item.selected{
  --color: #3f90b0;
  --background: white;
  --background-hover: grey;

}
.blue-text{
  --color: #3f90b0;
  color:#3f90b0;
}
